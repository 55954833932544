import { Observation, SexSpecification } from "jsgrowup2"

import { GrowthAssessment } from "@/models/GrowthAssessment"

const isDefined = (val: unknown) => val !== null && val !== undefined

export enum MeasurementType {
  Weight = "Weight",
  Length = "Length",
  Height = "Height",
  HeadSize = "Head size",
  ArmSize = "Arm size",
}

export enum Plausibility {
  Plausible = "Plausible",
  Unlikely = "Unlikely",
  UnlikelyAtBaseline = "Unlikely at baseline",
  Impossible = "Impossible",
}

/***
Returns a representation of the likelihood of this value being plausible,
as compared either with the previous measurement for this child, if it exists,
or against straight z scores.

Note: doesn't check whether this value fits in the acceptable range
for the measurement in question. However, if calculating the z score
raises an exception due to a value out of range, that results in
IMPOSSIBLE.

Logic: 
* For length/height and head size measurements, return IMPOSSIBLE if the previous
  measurement exceeds the current one (make length vs height comparable
  by adding 0.7cm to height to get length estimate).
* Otherwise, compare z-scores for measurement-for-age; if the abs value
  of their delta exceeds 1.0, return UNLIKELY.
* If this is a baseline assessment, calculate the relevant z score, and if it's
  extreme, return UNLIKELY AT BASELINE.
* In all other cases, PLAUSIBLE.
***/
export async function sanityCheckMeasurementValue(assessment: GrowthAssessment, value: number, type: MeasurementType): Promise<Plausibility> {
  const observation = new Observation(
    assessment.child.sex as SexSpecification,
    { dob: assessment.child.dob, dateOfObservation: assessment.dateOfAssessment }
  )
  await assessment.setPreviousAssessments()
  const prev = assessment.previousAssessments[0]
  if (prev) {
    await prev.setZScores()
  }
  let newZScore: number

  // * * *
  // Weight
  if (type === MeasurementType.Weight) {
    if (assessment.ageInYears >= 10) {
      return Plausibility.Plausible
    }
    try {
      newZScore = parseFloat(await observation.weightForAge(value))
    }
    catch {
      return Plausibility.Impossible
    }
    if (prev && isDefined(prev.zScoreWfa)) {
      return Math.abs(newZScore - prev.zScoreWfa) > 1 ? Plausibility.Unlikely : Plausibility.Plausible
    }
    // Baseline
    return -5 < newZScore && newZScore < 3 ? Plausibility.Plausible : Plausibility.UnlikelyAtBaseline
  }

  // * * *
  // Length/Height
  if (type === MeasurementType.Height || type === MeasurementType.Length) {
    try {
      newZScore = parseFloat(await observation.lengthOrHeightForAge(value))
    }
    catch {
      return Plausibility.Impossible
    }
    if (prev?.lengthInCm) {
      let compensation = 0
      if (type === MeasurementType.Length) {
        if (prev.typeOfLength === "height") {
          // Adjust to make length vs height measurements comparable.
          // (Hard to imagine why they'd go from measuring height to length though...)
          compensation = 0.7
        }
      }
      else if (prev.typeOfLength === "length") {
        // Current is height; previous was length
        compensation = -0.7
      }
      if (value < (prev.lengthInCm + compensation)) {
        return Plausibility.Impossible
      }
      return (Math.abs(newZScore - prev.zScoreLhfa) > 1) ? Plausibility.Unlikely : Plausibility.Plausible
    }
    return -5 < newZScore && newZScore < 3 ? Plausibility.Plausible : Plausibility.UnlikelyAtBaseline
  }

  // * * *
  // Head size
  if (type === MeasurementType.HeadSize) {
    newZScore = parseFloat(await observation.headCircumferenceForAge(value))
    try {
      newZScore = parseFloat(await observation.headCircumferenceForAge(value))
    }
    catch {
      return Plausibility.Impossible
    }
    if (prev?.headCircumferenceInCm) {
      if (value < prev.headCircumferenceInCm) {
        return Plausibility.Impossible
      }
      return Math.abs(newZScore - prev.zScoreHca) > 1 ? Plausibility.Unlikely : Plausibility.Plausible
    }
    else {
      // Baseline. In the case of head size, we make allowances for children with reported hydrocephalus.
      const upper = "hydrocephalus" in assessment.child.diagnoses ? 5 : 3
      return -5 < newZScore && newZScore < upper ? Plausibility.Plausible : Plausibility.UnlikelyAtBaseline
    }
  }

  // * * *
  // Arm size
  if (type === MeasurementType.ArmSize) {
    if (assessment.ageInYears < 5) {
      try {
        newZScore = parseFloat(await observation.armCircumferenceForAge(value))
      }
      catch {
        return Plausibility.Impossible
      }
      // We treat all measurements as baseline; we're not looking at trends for arm size
      return Math.abs(newZScore) > 5 ? Plausibility.UnlikelyAtBaseline : Plausibility.Plausible
    }
    return Plausibility.Plausible
  }
}
